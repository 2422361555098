<script>
export default {
  page() {
    return {
      title: this.$t("login.forgot"),
    };
  },
  data() {
    return {
      email: "",
      submitted: false,
      error: null,
      tryingToReset: false,
      isResetError: false,
      otp_status: false,
      changepasswd: false,
      form: {
        oldPassword: 'admin',
        newPassword: '',
        confirmPassword: ''
      },
      rules: {
        newPassword: [
          { required: true, message: this.$t('home.password.newpassword_tip'), trigger: 'blur' },
          { min: 6, message: this.$t('home.password.newpassword_length'), trigger: 'blur' },
          { validator: this.validateNewPassword, trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: this.$t('home.password.newpassword_check_tip'), trigger: 'blur' },
          { validator: this.validateConfirmPassword, trigger: 'blur' }
        ]
      },
    };
  },
  mounted() {
    document.body.classList.add("auth-body-bg");
    this.getotpstatus();
  },
  methods: {
    getotpstatus() {
      var that = this;
      that.$axios.post(that.apiuri, { action: "getotpstatus" }).then(function (response) {
        if (response.data.status == 200) {
          that.otp_status = true;
        }
      }).catch(function (error) { });
    },
    tryToReset() {
      if (!this.otp_status) {
        this.$alert(this.$t("resetpasswd.otp"), this.$t("update.status.tip"), {
          confirmButtonText: this.$t("global.button.ok"),
          callback: action => {

          }
        });
        return;
      }
      if (this.email == "") {
        this.$message({
          message: this.$t("resetpasswd.username.error"),
          type: "error",
        });
        return;
      }
      this.$prompt(
        this.$t("setting.system.otpbind_input"),
        this.$t("setting.system.otpbind_confirm"),
        {
          confirmButtonText: this.$t("global.button.ok"),
          cancelButtonText: this.$t("global.button.cancel"),
          inputPattern: /^[0-9]{6}$/,
          inputErrorMessage: this.$t("setting.system.otpbind_input_error"),
        }
      ).then(({ value }) => {
        this.$axios.post(this.apiuri, {
          action: "checkotptoken_online",
          code: value,
        },
          {
            headers: { Openid: this.userinfo.openid },
          }
        ).then((response) => {
          if (response.data.status === 200) {
            this.changepasswd = true;
          } else {
            this.$message({
              message: this.$t("setting.system.otp_failed"),
              type: "error",
            });
          }
        }).catch((error) => {
          this.$message({
            message: this.$t("setting.system.otpbind_error"),
            type: "error",
          });
        });
      }).catch(() => { });
    },
    sendPasswordChange() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "changeadminpwd",
            password: that.form.confirmPassword,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.gettodolist();
          if (response.data.status == 200) {
            that.showsuccess = true;
            that.$message.success(that.$t('home.password.success'));
          } else {
            that.$message.error(response.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  }
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-md-4 col-lg-2"></div>
          <div class="col-md-4 col-lg-8">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/login-logo.webp" width="60%" alt="logo" />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">{{ $t("resetpasswd.text") }}</h4>
                      </div>

                      <div class="p-2 mt-5">
                        <b-alert v-model="isResetError" class="mb-4" variant="danger" dismissible>{{ error }}</b-alert>
                        {{ changepasswd }}
                        <form class="form-horizontal" @submit.prevent="tryToReset" v-if="!changepasswd">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="useremail">{{ $t("resetpasswd.username.text") }}</label>
                            <input v-model="email" type="text" class="form-control" id="useremail"
                              :placeholder="$t('resetpasswd.username.placeholder')" />
                          </div>

                          <div class="mt-4 text-center">
                            <button class="btn btn-primary w-md waves-effect waves-light" type="submit">{{
                              $t("resetpasswd.text") }}</button>
                          </div>
                        </form>
                        <div v-else>
                          <el-form :model="form" :rules="rules" ref="form">
                            <el-form-item :label="$t('home.password.newpassword')" prop="newPassword">
                              <el-input v-model="form.newPassword" :placeholder="$t('home.password.newpassword_tip')"
                                show-password></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('home.password.newpassword_check')" prop="confirmPassword">
                              <el-input v-model="form.confirmPassword"
                                :placeholder="$t('home.password.newpassword_check_tip')" show-password></el-input>
                            </el-form-item>
                          </el-form>
                          <div slot="footer" class="dialog-footer">
                            <el-button type="primary" @click="sendPasswordChange">{{ $t("home.password.submit")
                              }}</el-button>
                          </div>
                        </div>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          {{ $t("resetpasswd.haveaccount") }}
                          <router-link tag="a" to="/login" class="font-weight-medium text-primary">{{
                            $t("resetpasswd.login") }}</router-link>
                        </p>
                        <p>
                          © 2016 Shijiazhuang MOE Information Technology Co., Ltd<br />
                          © 2015 AICO Group Limited company
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-lg-2"></div>
        </div>
      </div>
    </div>
  </div>
</template>